import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounce } from '../../../../shared/shared-module/utility/utility.functions';

@Component({
  selector: 'participant-dropdown',
  templateUrl: './participant-dropdown.component.html',
  styleUrls: ['./participant-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ParticipantDropdownComponent)
    }
  ]
})
export class ParticipantDropdownComponent
  implements OnChanges, ControlValueAccessor
{
  isShowDropdown = false;
  isShowSuggestionsDropdown = false;
  @Input() addElementOnInput = false;
  @Input() placeholder = '';
  @Input() disabledElement = false;
  @Input() staticUserSuggestions: {
    isLoading: boolean;
    data;
    error;
    nextPageNumber: number;
    pageSize: number;
  };
  @Input() fieldType;
  @Input() dynamicUserSuggestions: {
    isLoading: boolean;
    data;
    error;
    nextPageNumber: number;
    pageSize: number;
  };
  @Input() disAllowedEmailSuffix;
  @Input() allowedEmailSuffix;
  @Output() searchDynamicSuggestions = new EventEmitter<{
    pageNumber: number;
    pageSize: number;
    searchValue: string;
    isScroll: boolean;
  }>();

  selectedUsers = [];
  userInitialColor = ['#7C4DFF', '#FFAB00', '#00C853', '#FF5546'];
  searchValue = '';
  touched = false;
  disabled = false;
  @ViewChild('suggesstionWrapper', { static: false })
  suggesstionWrapper: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dynamicUserSuggestions &&
      this.dynamicUserSuggestions?.data?.length > 0
    ) {
      this.dynamicUserSuggestions.data.forEach((x) => {
        if (this.selectedUsers.findIndex((z) => z.email == x.email) > -1) {
          x['selected'] = true;
        } else {
          x['selected'] = false;
        }
      });
    }

    if (
      this.selectedUsers.length > 0 &&
      this.staticUserSuggestions?.data?.length > 0 &&
      !this.staticUserSuggestions.data.some((x) => x.selected)
    ) {
      this.staticUserSuggestions.data.forEach((x) => {
        if (this.selectedUsers.findIndex((z) => z.email == x.email) > -1) {
          x['selected'] = true;
        }
      });
    }
  }

  openDropdown() {
    setTimeout(() => {
      this.isShowDropdown = true;
    });
  }

  onCloseDropdown($event) {
    this.isShowSuggestionsDropdown = false;
    this.isShowDropdown = $event;
    this.searchValue = '';
  }

  toggleDropdown() {
    this.isShowSuggestionsDropdown = false;
    setTimeout(() => {
      this.isShowDropdown = !this.isShowDropdown;
    });
  }

  onKeyup(
    $event: KeyboardEvent,
    filteredStaticSuggstionCount: number,
    filteredStaticSuggstionEmail: string | null
  ) {
    if (!this.disabledElement) {
      if ($event.key == 'Enter') {
        if (!this.isShowDropdown && !this.isShowSuggestionsDropdown) {
          this.openDropdown();
        } else if (filteredStaticSuggstionEmail) {
          const emailObj = this.staticUserSuggestions?.data.find(
            (x) => x.email === filteredStaticSuggstionEmail
          );
          if (emailObj) {
            emailObj['selected'] = true;
            this.onChangeCheck(null, emailObj);
          }
        } else if (
          this.dynamicUserSuggestions &&
          !this.dynamicUserSuggestions.isLoading &&
          this.dynamicUserSuggestions.data.length === 1
        ) {
          const emailObj = this.dynamicUserSuggestions?.data[0];
          if (emailObj) {
            emailObj['selected'] = true;
            this.onChangeCheck(null, emailObj);
          }
        } else if (
          this.addElementOnInput &&
          this.searchValue.trim().length > 3 &&
          this.validateEmail(this.searchValue.trim()) &&
          this.selectedUsers.findIndex(
            (x) => x.email == this.searchValue.trim()
          ) == -1 &&
          (this.disAllowedEmailSuffix
            ? this.disAllowedEmailSuffix.every(
                (x) => !this.searchValue.trim().endsWith(x)
              )
            : true) &&
          (this.allowedEmailSuffix
            ? this.allowedEmailSuffix.some((x) =>
                this.searchValue.trim().endsWith(x)
              )
            : true)
        ) {
          this.selectedUsers.unshift({
            name: this.searchValue.trim(),
            email: this.searchValue.trim(),
            selected: true
          });
          this.isShowSuggestionsDropdown = false;
          this.isShowDropdown = false;
          this.searchValue = '';
          this.onChange(this.selectedUsers);
        }
      } else if ($event.key !== 'Enter') {
        if (filteredStaticSuggstionCount > 0) {
          this.isShowDropdown = true;
          this.isShowSuggestionsDropdown = false;
        } else if (
          this.dynamicUserSuggestions &&
          this.searchValue.trim().length >= 2
        ) {
          this.isShowDropdown = false;
          this.dynamicUserSuggestions.isLoading = true;
          this.isShowSuggestionsDropdown = true;
          this.searchDynamicSuggestionsEvent(false);
        } else {
          this.isShowSuggestionsDropdown = false;
        }
      }
    }
  }

  searchDynamicSuggestionsEvent = debounce((isScroll) => {
    this.loadDynamicSuggestions(isScroll);
  }, 300);

  loadDynamicSuggestions(isScroll: boolean) {
    this.searchDynamicSuggestions.emit({
      pageNumber: isScroll ? this.dynamicUserSuggestions?.nextPageNumber : 1,
      isScroll: isScroll,
      searchValue: !isScroll ? this.searchValue : '',
      pageSize: this.dynamicUserSuggestions?.pageSize
    });
  }

  onScroll() {
    if (
      !this.disabledElement &&
      this.isShowSuggestionsDropdown &&
      this.dynamicUserSuggestions &&
      this.dynamicUserSuggestions.nextPageNumber &&
      !this.dynamicUserSuggestions.isLoading
    ) {
      this.loadDynamicSuggestions(true);
    }
  }

  validateEmail(mail: string) {
    const mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; //eslint-disable-line
    if (mail.match(mailformat)) {
      return true;
    }
    return false;
  }

  onChangeCheck($event, userItem) {
    const index = this.selectedUsers.findIndex(
      (x) => x.email == userItem.email
    );
    if (userItem.selected && index === -1) {
      userItem['selected'] = true;
      this.selectedUsers.unshift(userItem);
    } else {
      userItem['selected'] = false;
      this.selectedUsers.splice(index, 1);
    }
    if (userItem['selected'] && this.isShowSuggestionsDropdown) {
      const idx = this.staticUserSuggestions.data.findIndex(
        (x) => x.email == userItem.email
      );
      if (idx != -1) {
        this.staticUserSuggestions.data[idx]['selected'] = true;
      }
    }
    this.onChange(this.selectedUsers);
  }

  removeSelectedUser(userItem) {
    const index = this.selectedUsers.findIndex(
      (x) => x.email == userItem.email
    );
    if (index != -1) {
      this.selectedUsers.splice(index, 1);
      const idx = this.staticUserSuggestions.data.findIndex(
        (x) => x.email == userItem.email
      );
      if (idx != -1) {
        this.staticUserSuggestions.data[idx]['selected'] = false;
      }
    }
    this.onChange(this.selectedUsers);
  }

  onSelectAll($event) {
    this.searchValue = '';
    if ($event.target.checked) {
      const addedItem = [];
      this.staticUserSuggestions.data
        .filter((x) => !x.selected)
        .forEach((z) => {
          z['selected'] = true;
          addedItem.push({ ...z, selected: true });
        });
      this.selectedUsers = [...addedItem, ...this.selectedUsers];
    } else {
      this.staticUserSuggestions.data.forEach((z) => {
        z['selected'] = false;
      });
      this.selectedUsers = this.selectedUsers.filter(
        (x) =>
          this.staticUserSuggestions.data.findIndex(
            (z) => x.email === z.email
          ) === -1
      );
    }
    this.onChange(this.selectedUsers);
  }

  selectAllExpertsBySkill($event, skill) {
    this.searchValue = '';
    if ($event.target.checked) {
      const addedItem = [];
      this.staticUserSuggestions.data
        .filter((x) => x.skill == skill && !x.selected)
        .forEach((z) => {
          z['selected'] = true;
          addedItem.push({ ...z, selected: true });
        });
      this.selectedUsers = [...addedItem, ...this.selectedUsers];
    } else {
      this.staticUserSuggestions.data
        .filter((x) => x.skill == skill)
        .forEach((z) => {
          z['selected'] = false;
        });
      this.selectedUsers = this.selectedUsers.filter(
        (x) =>
          this.staticUserSuggestions.data
            .filter((x) => x.skill == skill)
            .findIndex((z) => x.email === z.email) === -1
      );
    }
    this.onChange(this.selectedUsers);
  }

  isAllExpertsChecked(skill) {
    return (
      this.selectedUsers.length > 0 &&
      this.staticUserSuggestions?.data?.length > 0 &&
      this.staticUserSuggestions.data
        .filter((x) => x.skill == skill)
        .every((x) => x.skill == skill && x.selected)
    );
  }

  get isSelectAllChecked() {
    return (
      this.selectedUsers.length > 0 &&
      this.staticUserSuggestions?.data?.length > 0 &&
      this.staticUserSuggestions.data.every((x) => x.selected)
    );
  }

  ////////////////////// ControlvalueAccessor Implementation
  writeValue(selectedUsers) {
    if (selectedUsers) {
      this.selectedUsers = selectedUsers.map((x) => ({ ...x, selected: true }));
      if (!this.staticUserSuggestions.data.some((x) => x.selected)) {
        this.staticUserSuggestions.data.forEach((x) => {
          if (this.selectedUsers.findIndex((z) => z.email == x.email) > -1) {
            x['selected'] = true;
          }
        });
      }
    }
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onTouched = () => {}; // eslint-disable-line

  onChange = (v) => {}; // eslint-disable-line

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
