import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ProjectStatusService } from '../../../core/services/project-status.service';
import { PartnerdataModel } from '../models/partnerData/partnerdata.model';
import { UrlParameterModel } from '../models/url-parameters-model/url-parameter.model';
import { UserModel } from '../models/user-model/user.model';
import { CommonService } from './common.service';
import { LocalForageService } from './local-forage.service';
import { BehaviorSubject } from 'rxjs';
import { ClientSegmentModel } from '../models/client-segment.model';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable()
export class DataService {
  public user;
  public authToken: string;
  public urlParameters: UrlParameterModel;
  public partnerData: PartnerdataModel;
  public projectData;
  public releaseStatusList;
  public z_stub: string;
  public bugFix45DayWindow;
  isUserUpdated = new BehaviorSubject<boolean>(false); // is used in apiRequest for my_buildcard key update
  clientSegments: ClientSegmentModel;
  specCallDetails: any;

  constructor(
    public projectStatusService: ProjectStatusService,
    public commonService: CommonService,
    private localForageService: LocalForageService,
    private encrptDecryptService: EncryptDecryptService
  ) {
    this.user = new UserModel();
    this.partnerData = new PartnerdataModel();
    this.getPartnerData();
    this.setClientSegmentsData();
  }

  setClientSegmentsData() {
    this.clientSegments = {
      L1: {
        image: '../../../../../assets/images/minus-blue.svg',
        display_text: 'Medium/ Low'
      },
      L2: {
        image: '../../../../../assets/images/high-icon.svg',
        display_text: 'High'
      },
      L3: {
        image: '../../../../../assets/images/key-strategic.svg',
        display_text: 'Key strategic'
      }
    };
  }

  public forceRedirect(hasQues?) {
    const specIdParam = sessionStorage.getItem('force_redirection');
    if (specIdParam != undefined && specIdParam != null && specIdParam != '') {
      return `${!hasQues ? '?' : '&'}forceid=${specIdParam}`;
    } else {
      return '';
    }
  }

  public getServerUrl() {
    return environment.API_BASE_URL;
  }

  public getWebSocketUrl() {
    return environment.WEB_SOCKET_URL;
  }

  public getStudioWebSocketUrl() {
    return environment.STUDIO_WEB_SOCKET_URL;
  }

  public getPaymentUrl() {
    return environment.API_BASE_URL_PAYMENT;
  }

  public getPaymentToken() {
    return this.encrptDecryptService.d(environment.PAYMENT_TOKEN);
  }

  public getServerUrlForPartner() {
    return this.partnerData.vendor_backend_domain + '/api/pm/';
  }

  public getServerUrlForAccounts(projectData = null) {
    if (projectData && projectData?.studio_url?.backend_url) {
      return projectData?.studio_url?.backend_url + '/';
    } else {
      return environment.API_BASE_URL_FOR_ACCOUNTS;
    }
  }

  public getBuilderTrackerUrl() {
    return environment.BUILDER_TRACKER_URL;
  }

  public getBuilderTrackerSecretKey() {
    return this.encrptDecryptService.d(environment.BUILDER_TRACKER_SECRET_KEY);
  }

  public getAppTokenForAccounts() {
    return this.encrptDecryptService.d(environment.apptoken);
  }

  public getBChatWebScocketUrl() {
    return environment.BCHAT_WEBSOCKET_URL;
  }

  public storeUserObject() {
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('authToken', this.authToken);
  }

  public setInboxLeftWidth(width: number) {
    localStorage.setItem('leftWidth', width.toString());
  }

  public getInboxLeftWidth() {
    return localStorage.getItem('leftWidth')
      ? localStorage.getItem('leftWidth')
      : '600';
  }

  public storeAutoComplete(remeberMe: string) {
    localStorage.setItem('auto', remeberMe);
  }

  public getAutoComplete() {
    return localStorage.getItem('auto')
      ? JSON.parse(localStorage.getItem('auto'))
      : null;
  }

  public getRequestOptionArgs(
    contentType?: string,
    isFromUrl?: boolean,
    body?
  ) {
    const httpOptions = { headers: new HttpHeaders(), body: body };
    if (contentType) {
      httpOptions.headers = httpOptions.headers.set(
        'Content-Type',
        contentType
      );
    }
    const user = this.getUserData();
    if (user) {
      const token = this.getAuthToken();
      if (token) {
        httpOptions.headers = httpOptions.headers.set('auth-token', token);
      }
    }

    const partnerData = JSON.parse(localStorage.getItem('partnerData'));
    const partnerCode = partnerData
      ? partnerData.code
        ? partnerData.code
        : 'e-ai'
      : 'e-ai';
    httpOptions.headers = httpOptions.headers.set('partner-code', partnerCode);
    return httpOptions;
  }

  public underscore(key) {
    return key.replace(/([A-Z])/g, ($1) => {
      return '_' + $1.toLowerCase();
    });
  }

  public getSubUrl(url: string) {
    const urlSplit = url.split('/');
    if (urlSplit[3]) {
      return urlSplit.filter((x, index) => index >= 3).join('/');
    } else {
      return '';
    }
  }

  public setIncomingUrlParameters(urlParameters: UrlParameterModel) {
    if (urlParameters) {
      this.urlParameters = urlParameters;
    }
  }

  public async logout() {
    this.projectStatusService.projectTabStatus = '';
    this.user = null;
    this.authToken = null;
    localStorage.setItem('user', '');
    localStorage.setItem('authToken', '');
    localStorage.setItem('clickedIds', JSON.stringify([]));
    this.commonService.newHeadertrue = true;
    try {
      await this.localForageService.clearStore();
    } catch (error) {
      console.error('Error clearing localForage store:', error);
    }
  }

  public getUserData() {
    this.user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
    return this.user;
  }

  public getUserDepartment() {
    this.user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;

    return this.user ? this.user.department : null;
  }

  public getAuthToken() {
    this.authToken = localStorage.getItem('authToken')
      ? localStorage.getItem('authToken')
      : null;
    return this.authToken;
  }

  getUserEmail() {
    this.user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
    return this.user?.email;
  }

  public getPartnerData() {
    this.partnerData = localStorage.getItem('partnerData')
      ? JSON.parse(localStorage.getItem('partnerData'))
      : null;
    return this.partnerData;
  }

  public platFormDetailsIcon() {
    this.projectData = localStorage.getItem('platformDetails')
      ? JSON.parse(localStorage.getItem('platformDetails'))
      : null;
    return this.projectData;
  }

  public releaseStatus() {
    this.releaseStatusList = localStorage.getItem('release_status')
      ? JSON.parse(localStorage.getItem('release_status'))
      : null;
    return this.releaseStatusList;
  }

  public bugFixDate() {
    this.bugFix45DayWindow = localStorage.getItem('bug_fix_prescribed_date')
      ? localStorage.getItem('bug_fix_prescribed_date')
      : null;
    return this.bugFix45DayWindow;
  }

  public getZoomClientId() {
    return this.encrptDecryptService.d(environment.ZOOMCLIENTID);
  }

  public getGoogleClientId() {
    return this.encrptDecryptService.d(environment.GOOGLE_CLIENT_ID);
  }
  public getGoogleSecretkey() {
    return this.encrptDecryptService.d(environment.GOOGLE_CLIENT_SECRET_KEY);
  }
  public getGitLabUrl() {
    return environment.GITLABURL;
  }

  public getFrontAppAuthToken() {
    return this.encrptDecryptService.d(environment.FRONTAPP_AUTH_TOKEN);
  }

  public getFrontAppUrl() {
    return environment.FRONTAPPURL;
  }

  getBMeetToken() {
    return this.encrptDecryptService.d(environment.BMEET_TOKEN);
  }

  getBMeetURL() {
    return environment.BMEET_URL;
  }

  getPartnerCode() {
    let partnerCode = '';
    const partnerData = localStorage.getItem('partnerData');
    if (partnerData) {
      partnerCode = JSON.parse(partnerData).code;
    }
    return partnerCode;
  }

  public getByIndex(array, index) {
    for (const thing of array) {
      if (thing.index === index) {
        return thing;
      }
    }
  }
  getSchedulerToken() {
    return this.encrptDecryptService.d(environment.SCHEDULER_TOKEN);
  }

  getUserAvailUrl() {
    return environment.API_AVAIL_BASE_URL;
  }

  getBuilderHomeURL() {
    return environment.B_HOME;
  }

  getBuilderHomeSecretKey() {
    return this.encrptDecryptService.d(environment.B_HOME_SECRET_KEY);
  }

  getBuilderHomeWebsocketURL() {
    return environment.B_HOME_WEBSOCKET_URL;
  }

  getCloudUrl() {
    return environment.CLOUD_API_URL;
  }
  getCloudAuthToken() {
    return this.encrptDecryptService.d(environment.CLOUD_AUTH_TOKEN);
  }

  getQlikBaseUrl() {
    return environment.QLIK_BASE_URL;
  }

  getCustomisationBudgetToken() {
    return this.encrptDecryptService.d(environment.CUSTOMISATION_BUDGET_TOKEN);
  }

  getCMTPassword() {
    return this.encrptDecryptService.d(environment.CMT_Password);
  }

  getHIVEPassword() {
    return this.encrptDecryptService.d(environment.HIVE_Password);
  }

  getNotificationToken() {
    return this.encrptDecryptService.d(environment.NOTIFICATION_TOKEN);
  }

  getSchedulerTokenisedUrl() {
    return (
      environment.SCHEDULER_URL +
      '/?token=' +
      this.encrptDecryptService.d(environment.SCHEDULER_TOKEN)
    );
  }
}
