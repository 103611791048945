import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  // Constants based on your Validation.js file
  AUTH_VALIDATION_MESSAGE = {
    PASSWORD_REQUIRED: 'Password cannot be blank.',
    PASSWORD_MINIMUM_LENGTH: 'Enter minimum 8 characters',
    PASSWORD_CONTAIN_LOWERCASE:
      'Password must contain at least 1 lowercase character',
    PASSWORD_CONTAIN_UPPERCASE:
      'Password must contain at least 1 uppercase character',
    PASSWORD_CONTAIN_NUMBER: 'Password must contain at least 1 number',
    PASSWORD_MATCHES_EMAIL: 'Password must be different than the email.',
    PASSWORD_CONTAIN_SPACE: 'Password cannot contain spaces.'
  };

  hasMinLength(str: string) {
    return str.length > 8;
  }

  /**
   * Checks if a string has uppercase characters
   * @param str The string to check
   */
  hasUpperCase(str: string): boolean {
    return /[A-Z]/.test(str);
  }

  /**
   * Checks if a string has lowercase characters
   * @param str The string to check
   */
  hasLowerCase(str: string): boolean {
    return /[a-z]/.test(str);
  }

  /**
   * Checks if a string has numeric characters
   * @param str The string to check
   */
  hasNumber(str: string): boolean {
    return /\d/.test(str);
  }

  /**
   * Validates a password for UI feedback
   * @param password The password to validate
   * @param email Optional email for comparison
   * @returns null if valid, UI error message if invalid
   */
  passwordValidationUI(password: string, email?: string): string | null {
    if (!password) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_REQUIRED;
    }

    // Other validation messages for UI feedback, these will be shown
    if (password.includes(' ')) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_SPACE;
    }

    if (email && password.trim().toLowerCase() === email.trim().toLowerCase()) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_MATCHES_EMAIL;
    }

    // Return null if no other relevant validation messages are applicable
    return null;
  }

  /**
   * Validates a password
   * @param password The password to validate
   * @returns null if valid, error message if invalid
   */
  passwordValidation(password: string, email?: string): string | null {
    if (!password) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_REQUIRED;
    }

    if (!this.hasMinLength(password)) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_MINIMUM_LENGTH;
    }

    if (!this.hasLowerCase(password)) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_LOWERCASE;
    }

    if (!this.hasUpperCase(password)) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_UPPERCASE;
    }

    if (!this.hasNumber(password)) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_NUMBER;
    }

    if (password.indexOf(' ') >= 0) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_SPACE;
    }

    if (
      email &&
      password?.trim().toLowerCase() === email?.trim().toLowerCase()
    ) {
      return this.AUTH_VALIDATION_MESSAGE.PASSWORD_MATCHES_EMAIL;
    }

    return null;
  }
}
