// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'https://api-staging-pmdashboard.engineer.ai/api/v1/pm/',
  QLIK_BASE_URL: 'https://api.data.builder.ai/',
  CUSTOMISATION_BUDGET_TOKEN:
    'U2FsdGVkX1+m1CwpwFP5AaZ7JSmbF8wPq7dXCd1vY+J59GtOdFiK8VmTyBllJJExcvfqL93PU3HJusQ2KwbJ5w==',
  API_BASE_URL_FOR_ACCOUNTS:
    'https://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/',
  STUDIO_4_BASE_URL:
    'https://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/',
  WEB_SOCKET_URL: 'wss://api-staging-pmdashboard.engineer.ai/cable',
  STUDIO_WEB_SOCKET_URL: 'wss://api-staging-builder.engineer.ai/cable',
  apptoken: 'U2FsdGVkX1+29pKcTxfPUaNwlScL4d8rqTHlXKLN5Vl7uu9DACPWEWpjoio0j58g',
  STUDIO_API_BASE_URL:
    'https://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/',
  BASE_URL: 'https://staging-pmdashboard.engineer.ai/#/',
  API_BASE_URL_PAYMENT: 'http://staging-payments.engineer.ai/',
  PAYMENT_TOKEN:
    'U2FsdGVkX1+R1J8MM8pDSVxMRY4JFeD7oQq7Xp7G6o4Veg7tKaIfG3TyqRad/lgW5VOQ2F6Sn7J8+/1/mDarD9uGAkwfyuA5r6HszX6w/LUmAl/7eSZt7q3ZQ1i3XaN0YUA1sCWUfq1Ovf9NPl3bSA==',
  ZOOMCLIENTID:
    'U2FsdGVkX19l7ElGz/O17VhnnRAKVdXqbmN7V3Uf10APEC+P5IlJRh6GpxPLSz5e',
  GOOGLE_CLIENT_ID:
    'U2FsdGVkX19Y+oNIMyGOG5elhXFRMP1qv9m4QUxCL76hbKAn3HrslYLs4oGb7usspnWRdL0LroP2DvaXnaqDkZ/comJ8X4yE/vjr115mEGBBhMGYLex6PqQZbTxmvrk9',
  GOOGLE_CLIENT_SECRET_KEY:
    'U2FsdGVkX1861Z+NZGTXuuqqzQacgYsAvo1sStD9llGS4+kMxq/Sw1zCWTWEWoKZ',
  BMEET_TOKEN:
    'U2FsdGVkX19DGIByTo2ATqDbSxSd2Dw18mfsNr9e/uBzTh/NqVZUOBq5QQybCOk5',
  BMEET_URL: 'https://meet-backend-staging.builder.ai/api/v1/',
  GITLABURL: 'https://staging.gitlab.builder.ai/clientprojects',
  FRONTAPP_AUTH_TOKEN:
    'U2FsdGVkX18+OJ1vp5obg+qYij6nKsyVI64AT1oci6LoOKzR5BLqYivY2NxDVpcE2h6H4uvc+dfkWwEFNKVh4BKIghTfkO6is9Emi+Z2rGaWEHUf5YUAeW4YpTq+FVSWtHqDa05sZzvk1zvIQjfedBykZs0adZEV1kraqPWj2LfBwXtzwQtpSrUQ7yoPaAMsmkXOjahQNSuMTyUoUKbExgcX4oZ6GUuMytwFbx6OM2m4BYral5U89UzMrtvzb4xHEPCpYHofNkmrEx/Xexpw6++25UHCOx/sW/wNfcT4PBnqW7dxwYGrpXSMEy+ypne1R0TXf3FKbvw9NeSUvU1nzyd7Tod8/PCcbkgd0W6jJHw=',
  FRONTAPPURL: 'https://api2.frontapp.com',
  SCHEDULER_URL: 'https://staging-scheduler.builder.ai',
  SCHEDULER_TOKEN:
    'U2FsdGVkX1+d0eWGtRtNWsir8YKTKcxnPEvlMzgvnus7h3MgNruEzDRmQvlm2noB',
  SFID_URL:
    'https://engineerai--newprocess.lightning.force.com/lightning/r/Build_card_object_spec__c/SF_BuildCard_ID/view',
  BUILDER_STUDIO_URL: 'https://studio.builder.ai/',
  CLOUD_AUTH_TOKEN:
    'U2FsdGVkX1+6etz9QFvwgcuBfgYUpppJaKGToBepQ6oslM5S2zQ3B0fWTMODYkO0+C6heRNSmeK2EeuzF5z3+g==',
  CLOUD_API_URL: 'https://staging-api.cat.builder.ai/v1/',
  BUILDER_TRACKER_URL: 'https://staging.api.tracker.engineer.ai/api/v1/',
  BUILDER_TRACKER_SECRET_KEY:
    'U2FsdGVkX19F90pjq1yB9qnrQYUBDTk2PRuhImRnTOINoLP86JidwtYeMOU21PTH',
  ENABLE_SENTRY: true,
  ENABLE_SERVICE_WORKER: true,
  MICROFRONTENDS: {
    manager: {
      remoteUrl: 'https://builder360-staging-managers.azureedge.net/'
    }
  },
  name: 'staging',
  PARTNER_CODE: 'e-ai',
  API_AVAIL_BASE_URL: 'https://api-staging-schedular.builder.ai/api/v1/',
  CMT_API_URL: 'https://cmt-staging.builder.ai/api/v2/',
  CMT_Username: 'b360_staging',
  CMT_Password:
    'U2FsdGVkX19UCg2Toy4fNlzvKjgIb0t6nDYoVl1dQ2bVer5X9mEfIWHhSh+BVbK7OAHgGMMUebi1oGM+rx69lQ==',
  HIVE_Username: 'admin@engineer.ai',
  HIVE_Password:
    'U2FsdGVkX19PJBkDahfURwr0swu3kz5LlAsPLEtragOoRW/ljl1/OEXHxKwZCyad',
  WHITEBOARD_URL: 'https://staging-whiteboard.builder.ai',
  B_HOME: 'https://b-home.bhome.stage.eastus.az.svc.builder.ai/api/v2/bhome/',
  B_HOME_SECRET_KEY:
    'U2FsdGVkX1/Lkcc9q4I2RsZminfi4FlwfsMFx1iWZ9FuY5PQMeSUrWqXNp1iSCH0Se9tDGCQ/pO5A3f+VBHzow==',
  B_HOME_WEBSOCKET_URL:
    'wss://b-home.bhome.stage.eastus.az.svc.builder.ai/cable',
  BCHAT: 'https://staging-chat-pmdashboard.engineer.ai/',
  BCHAT_WEBSOCKET_URL: 'wss://staging-chat.builder.ai/cable',
  UAE_STUDIO_BASE_URL: 'https://api-staging-builder.engineer.ai/',
  B_Now_URL: 'https://staging-now.engineer.ai/',
  NOTIFICATION_TOKEN:
    'U2FsdGVkX1+3m1fAsrF5OrFSfBh59f4+KaWGYX+NuElNm9PySTpR7hgaUOxaNcUqrfbjP44+QmbRVn5mpQm2jAAPa0gazk1m0unZgH1M9jYVRwZArJTCLNiBYnBnQPL/odP6yOeHIumecu5h1isIMly0N1fNmvytcjyVVI2v/SokUOBXvwkXQpX5wZg8RQWKeUanotf6sEg2B2+fGLF07A==',
  NOTIFICATION_NEXUS_URL:
    'https://notify-nexus.b360.stage.eastus.az.svc.builder.ai/cable'
};
