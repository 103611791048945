import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ToastMessageService } from '../../core/services/toast.message.service';

@Injectable()
export class APIResponseHandlerInterceptor implements HttpInterceptor {
  constructor(private toastMessageService: ToastMessageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // discard client side error
        if (error?.error && !(error.error instanceof ErrorEvent)) {
          // Show svg_validation_error toast notification
          const svgValidationErrorKey = error.error?.error
            ? Object.keys(error.error.error).find((key) =>
                key.includes('svg_validation_error')
              )
            : null;
          if (
            svgValidationErrorKey &&
            error.error.error[svgValidationErrorKey]
          ) {
            setTimeout(() => {
              this.toastMessageService.showToastMessage(
                error.error.error[svgValidationErrorKey],
                5000,
                true
              );
            }, 0);
          }
        }

        return throwError(() => error);
      })
    );
  }
}
