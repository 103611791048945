<section class="middle-sec common-redesign">
  <div class="pm-dashboard-modal">
    <h2>Reset Password</h2>
    <form class="common-form" #resetform="ngForm" name="resetform">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="new_pass">Enter New Password</label>
            <div class="common-input-box sa-password-box">
              <input
                id="new_pass"
                type="{{ hideShow.passShow ? 'text' : 'password' }}"
                name="password"
                class="common-input"
                required
                maxlength="100"
                minlength="8"
                #password="ngModel"
                [(ngModel)]="data.password"
                autocomplete="off"
              />
              <i
                aria-hidden="true"
                [ngClass]="{ active: hideShow.passShow }"
                class="clear-icon"
                aria-hidden="true"
                (click)="hideShow.passShow = !hideShow.passShow"
                *ngIf="data.password"
              ></i>
            </div>
            <div
              class="invalid-feedback fs-5"
              *ngIf="validationService.passwordValidationUI(data.password)"
            >
              {{ validationService.passwordValidationUI(data.password) }}
            </div>
            <div
              *ngIf="
                (password?.touched || password?.dirty) &&
                !password?.errors?.required
              "
              class="mt-3"
            >
              <span
                [ngClass]="
                  validationService.hasMinLength(data.password)
                    ? 'text-success'
                    : 'text-muted'
                "
                class="d-block mb-3"
              >
                <fa-icon icon="check"></fa-icon>&nbsp;Enter minimum 8
                characters</span
              >
              <span
                [ngClass]="
                  validationService.hasUpperCase(data.password) &&
                  validationService.hasLowerCase(data.password)
                    ? 'text-success'
                    : 'text-muted'
                "
                class="d-block text-success mb-3"
              >
                <fa-icon icon="check"></fa-icon>&nbsp;At least 1 uppercase and 1
                lowercase letter</span
              >
              <span
                [ngClass]="
                  validationService.hasNumber(data.password)
                    ? 'text-success'
                    : 'text-muted'
                "
                class="d-block text-success mb-3"
              >
                <fa-icon icon="check"></fa-icon>&nbsp;At least 1 number</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb0">
            <label for="confirm_pass">Enter Confirm Password</label>
            <div class="common-input-box sa-password-box">
              <input
                id="confirm_pass"
                type="{{ hideShow.confirmShow ? 'text' : 'password' }}"
                name="cpassword"
                class="common-input"
                required
                autocomplete="off"
                maxlength="100"
                minlength="8"
                #cpassword="ngModel"
                [(ngModel)]="data.password_confirmation"
              />
              <i
                [ngClass]="{ active: hideShow.confirmShow }"
                class="clear-icon"
                aria-hidden="true"
                (click)="hideShow.confirmShow = !hideShow.confirmShow"
                *ngIf="data.password"
              ></i>
            </div>
            <div
              class="invalid-feedback fs-5"
              *ngIf="cpassword?.errors?.required"
            >
              Password cannot not be blank.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mt0">
            <span class="invalid-feedback">{{ CommonErrorMsg }}</span>
            <p class="tick" *ngIf="checkPass()">Password Match</p>
            <p
              class="passwordNotMatch invalid-feedback"
              *ngIf="checkPass() === false"
            >
              <fa-icon icon="times-circle"></fa-icon>
              Passwords do not match
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group m12">
            <input
              type="submit"
              class="btn btn-primary comm-login-module-btn"
              name="submit"
              value="Reset"
              (click)="resetClick()"
              [disabled]="
                resetform.invalid ||
                checkPass() === false ||
                checkPassword() ||
                checkConfirmPassword()
              "
            />
          </div>
        </div>
      </div>
    </form>
    <p>
      Back to
      <a [routerLink]="['/login']">Login</a>
    </p>
  </div>
</section>
