import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { AngularMyDatePickerModule } from 'angular-mydatepicker-ivy';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsetConfig, TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProbableDateComponent } from '../../core/project-module/probable-date/probable-date.component';
import { CustomAngularEditorModule } from '../../shared/custom-angular-editor/custom-angular-editor.module';
import { MomentModule } from '../angular2-moment-pipe/moment.module';
import { BootstrapImportsModule } from './bootstrap-imports.module';
import { AddDelayComponent } from './components/add-delay/add-delay.component';
import { BuildcardTagsComponent } from './components/buildcard-tags/buildcard-tags.component';
import { CommonButtonToNavigateComponent } from './components/common-button-nav/common-button-nav.component';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { CommonSearchComponent } from './components/common-search/common-search.component';
import { CustomBubbleComponent } from './components/custom-bubble/custom-bubble.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { CustomGroupMuliSelectDropdownComponent } from './components/custom-group-multiselect-dropdown/custom-group-multiselect-dropdown.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { CustomMultiselectDropdownComponent } from './components/custom-multiselect-dropdown/custom-multiselect-dropdown.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { CustomSelectableDropdownComponent } from './components/custom-selectable-dropdown/custom-selectable-dropdown.component';
import { CustomSingleDropdownComponent } from './components/custom-single-select-dropdown/custom-single-select-dropdown.component';
import { CustomSwitchComponent } from './components/custom-switch/custom-switch.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { DelayTimelineComponent } from './components/delay-timeline/delay-timeline.component';
import { EmailChipInputComponent } from './components/email-chip-input/email-chip-input.component';
import { GridListViewComponent } from './components/grid-list-view/grid-list-view.component';
import { TitleBlockInfoWithTooltipComponent } from './components/inner-page-title-info/inner-page-title-info.component';
import { InnerPageTitleComponent } from './components/inner-page-title/inner-page-title.component';
import { InputFieldGroupComponent } from './components/input-field-group/input-field-group.component';
import { LinkGoogleCalendarComponent } from './components/link-google-calendar/link-google-calendar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { ModalComponent } from './components/modal/modal.component';
import { ConfirmModalPopupComponent } from './components/modals/confirm-modal-popup/confirm-modal-popup.component';
import { NewTimeZoneComponent } from './components/new-timezone/new-timezone.component';
import { PhotoUploaderComponent } from './components/photo-uploader/photo-uploader.component';
import { ProjectCardCommentComponent } from './components/project-card-comment/project-card-comment.component';
import { ProjectChecklistCodeRepoFormComponent } from './components/project-checklist-code-repo-form/project-checklist-code-repo-form.component';
import { RadioDropDownComponent } from './components/radio-dropdown/radio-dropdown.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { SelectDropdownFieldComponent } from './components/select-dropdown-field/select-dropdown-field.component';
import { SimilarBuilcardsComponent } from './components/similar-builcards/similar-builcards.component';
import { SvgIconComponent } from './components/svg-icons/svg-icon.component';
import { ThreeDotComponent } from './components/three-dot-svg/three-dot-svg.component';
import { TimeZoneComponent } from './components/time-zone/time-zone.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { DisableKeyEventDirective } from './directives/disable-key-event.directive';
import { ElementDetectDirective } from './directives/elem-detect.directive';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { AddTabIndexDirective } from './directives/tab-index.directive';
import { FontAwesomeIconModule } from './font-awesome-icon.module';
import { bbStatusTextPipe } from './pipes/bbStatusText.pipe';
import { CatalogueBlocksGroupBySkillPipe } from './pipes/catalogue-blocks-group-by-skill.pipe';
import { CommentNamePipe } from './pipes/comment.name.pipe';
import { CustomPhaseFilterPipe } from './pipes/custom-phase-filter.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DesignationMapValuePipe } from './pipes/designation-map-value.pipe';
import { DeviceNamePipe } from './pipes/device_name.pipe';
import { FilenamePipe } from './pipes/filename.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { filterTextPipe } from './pipes/filterText.pipe';
import { GenericTimezoneConversionPipe } from './pipes/generic-timezone.pipe';
import { HighlightTextPipe } from './pipes/highlightText.pipe';
import { NameInitialPipe } from './pipes/name-initial.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { OrdinalSuffixPipe } from './pipes/ordinal-suffix.pipe';
import { PromoFilterPipe } from './pipes/promo-filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { SingleLastNamePipe } from './pipes/single.last.name.pipe.';
import { TextTransformPipe } from './pipes/textTransform.pipe';
import { TimezoneFilterPipe } from './pipes/timezone-filter.pipe';
import { timezoneTextOnly } from './pipes/timezoneTextOnly.pipe';
import { ApiRequests } from './services/apiRequests.service';
import { CommonService } from './services/common.service';
import { DataService } from './services/data.service';
import { HttpRequests } from './services/http-requests.service';
import { ModelMapperService } from './services/model-mapper.service';
import { SharedService } from './services/shared.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

//components
@NgModule({
  imports: [
    TooltipModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayModule,
    MomentModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    CustomAngularEditorModule,
    RouterModule,
    TabsModule,
    InfiniteScrollModule,
    MatSelectModule,
    MatInputModule,
    FontAwesomeIconModule,
    ClipboardModule,
    AngularMyDatePickerModule,
    SafeHtmlPipe
  ],
  declarations: [
    ModalComponent,
    ProjectChecklistCodeRepoFormComponent,
    FilterPipe,
    FilenamePipe,
    CommentNamePipe,
    OrderByPipe,
    TimezoneFilterPipe,
    PromoFilterPipe,
    SingleLastNamePipe,
    ConfirmModalPopupComponent,
    filterTextPipe,
    bbStatusTextPipe,
    CustomPhaseFilterPipe,
    ProbableDateComponent,
    DisableKeyEventDirective,
    timezoneTextOnly,
    DesignationMapValuePipe,
    CustomPhaseFilterPipe,
    TimeZoneComponent,
    CustomPopupComponent,
    EmailChipInputComponent,
    LinkGoogleCalendarComponent,
    AddDelayComponent,
    DelayTimelineComponent,
    LoadingButtonComponent,
    TextTransformPipe,
    CustomToastComponent,
    LoaderComponent,
    CustomModalComponent,
    ToasterComponent,
    CommonFilterComponent,
    InnerPageTitleComponent,
    InputFieldGroupComponent,
    CustomCheckboxComponent,
    CustomSelectableDropdownComponent,
    CustomSwitchComponent,
    SelectDropdownFieldComponent,
    PhotoUploaderComponent,
    GridListViewComponent,
    CommonSearchComponent,
    TitleBlockInfoWithTooltipComponent,
    ThreeDotComponent,
    SvgIconComponent,
    CommonButtonToNavigateComponent,
    ElementDetectDirective,
    AddTabIndexDirective,
    NewTimeZoneComponent,
    CustomBubbleComponent,
    CustomMultiselectDropdownComponent,
    RadioDropDownComponent,
    HighlightTextPipe,
    DeviceNamePipe,
    ReadMoreComponent,
    SafePipe,
    ScrollSpyDirective,
    SimilarBuilcardsComponent,
    DateAgoPipe,
    ScrollSpyDirective,
    SafePipe,
    DateAgoPipe,
    SimilarBuilcardsComponent,
    SentenceCasePipe,
    CustomGroupMuliSelectDropdownComponent,
    CustomSingleDropdownComponent,
    OrdinalSuffixPipe,
    CatalogueBlocksGroupBySkillPipe,
    GenericTimezoneConversionPipe,
    NameInitialPipe,
    ProjectCardCommentComponent,
    BuildcardTagsComponent
  ],

  exports: [
    ModalComponent,
    ProjectChecklistCodeRepoFormComponent,
    CustomPhaseFilterPipe,
    FilterPipe,
    PromoFilterPipe,
    FilenamePipe,
    CommentNamePipe,
    OrderByPipe,
    SingleLastNamePipe,
    TimezoneFilterPipe,
    ModalModule,
    filterTextPipe,
    bbStatusTextPipe,
    PopoverModule,
    ConfirmModalPopupComponent,
    ProbableDateComponent,
    DisableKeyEventDirective,
    timezoneTextOnly,
    CustomPopupComponent,
    TimeZoneComponent,
    EmailChipInputComponent,
    LinkGoogleCalendarComponent,
    AddDelayComponent,
    DelayTimelineComponent,
    LoadingButtonComponent,
    TextTransformPipe,
    CustomToastComponent,
    DesignationMapValuePipe,
    LoaderComponent,
    CustomModalComponent,
    ToasterComponent,
    BootstrapImportsModule,
    CommonFilterComponent,
    InnerPageTitleComponent,
    InputFieldGroupComponent,
    CustomCheckboxComponent,
    CustomSelectableDropdownComponent,
    CustomSwitchComponent,
    SelectDropdownFieldComponent,
    PhotoUploaderComponent,
    GridListViewComponent,
    CommonSearchComponent,
    TitleBlockInfoWithTooltipComponent,
    ThreeDotComponent,
    SvgIconComponent,
    CommonButtonToNavigateComponent,
    ReactiveFormsModule,
    FormsModule,
    ElementDetectDirective,
    AddTabIndexDirective,
    TabsModule,
    NewTimeZoneComponent,
    CustomBubbleComponent,
    CustomMultiselectDropdownComponent,
    RadioDropDownComponent,
    HighlightTextPipe,
    DeviceNamePipe,
    ReadMoreComponent,
    FontAwesomeIconModule,
    SafePipe,
    ScrollSpyDirective,
    SimilarBuilcardsComponent,
    DateAgoPipe,
    SentenceCasePipe,
    CustomGroupMuliSelectDropdownComponent,
    CustomSingleDropdownComponent,
    OrdinalSuffixPipe,
    CatalogueBlocksGroupBySkillPipe,
    ClipboardModule,
    NameInitialPipe,
    GenericTimezoneConversionPipe,
    ProjectCardCommentComponent,
    BuildcardTagsComponent,
    SafeHtmlPipe
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CommonService,
        SharedService,
        ApiRequests,
        HttpRequests,
        DataService,
        ModelMapperService,
        TabsetConfig
      ]
    };
  }
}
