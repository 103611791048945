import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  private readonly s = 'MtPdG8bpbaGu8iLJyyLORRuJ9RqhrQ2pZqYhqoO0Xw6gYP5t';
  e(E: string): string {
    try {
      return CryptoJS.AES.encrypt(E, this.s).toString();
    } catch (I) {
      console.error('Encryption Error:', I);
      return '';
    }
  }
  d(E: string): string {
    try {
      return CryptoJS.AES.decrypt(E, this.s).toString(CryptoJS.enc.Utf8);
    } catch (I) {
      console.error('Decryption Error:', I);
      return '';
    }
  }
}
