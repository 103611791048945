<section class="middle-sec common-redesign">
  <div class="pm-dashboard-modal">
    <div *ngIf="!showVerifyPage">
      <h2>Sign Up</h2>
    </div>
    <form
      class="common-form"
      #signupform="ngForm"
      name="signupform"
      *ngIf="!showVerifyPage"
    >
      <div class="row">
        <div class="col-md-6">
          <div
            class="form-group"
            [ngClass]="{ crossInviteSignup: isCrossInvite }"
          >
            <label for="firstname">First Name</label>
            <div class="common-input-box sa-name-box">
              <input
                id="firstname"
                type="text"
                name="firstName"
                placeholder="Your first name?"
                class="common-input"
                required
                autocomplete="off"
                maxlength="100"
                #firstName="ngModel"
                pattern="[a-zA-Z0-9\/.\s]+"
                [(ngModel)]="data.first_name"
              />
            </div>
            <div class="invalid-feedback" *ngIf="checkFirstNameValid()">
              Only alphanumeric characters are allowed
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="form-group"
            [ngClass]="{ crossInviteSignup: isCrossInvite }"
          >
            <label for="lastname">Last Name</label>
            <div class="common-input-box sa-name-box">
              <input
                type="text"
                id="lastname"
                placeholder="Your last name?"
                name="lastName"
                class="common-input"
                required
                autocomplete="off"
                maxlength="100"
                #lastName="ngModel"
                pattern="[a-zA-Z0-9\/.\s]+"
                [(ngModel)]="data.last_name"
              />
            </div>
            <div class="invalid-feedback" *ngIf="checkLastNameValid()">
              Only alphanumeric characters are allowed
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="form-group"
            [ngClass]="{ crossInviteSignup: isCrossInvite }"
          >
            <label for="email_bx">Email address</label>
            <div class="common-input-box sa-email-box">
              <input
                id="email_bx"
                placeholder="You@example.com"
                type="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="data.email"
                maxlength="100"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                class="common-input"
                required
                autocomplete="off"
              />
            </div>
            <div class="invalid-feedback fs-5" *ngIf="checkIfEmailValid()">
              Please enter valid email
            </div>
            <span class="invalid-feedback fs-5">{{ CommonErrorMsg }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="password_bx">Password</label>
            <div class="common-input-box sa-password-box">
              <input
                name="password"
                id="password_bx"
                placeholder="8+ characters"
                class="common-input"
                maxlength="30"
                minlength="8"
                #password="ngModel"
                [(ngModel)]="data.password"
                type="{{ pass.show ? 'text' : 'password' }}"
                required
                autocomplete="off"
              />
              <i
                [ngClass]="{ active: pass.show }"
                class="clear-icon"
                aria-hidden="true"
                (click)="showPassword()"
                *ngIf="data.password"
              ></i>
            </div>
            <div>
              <div
                class="invalid-feedback fs-5"
                *ngIf="
                  validationService.passwordValidationUI(
                    data.password,
                    data.email
                  )
                "
              >
                {{
                  validationService.passwordValidationUI(
                    data.password,
                    data.email
                  )
                }}
              </div>
            </div>
            <div
              *ngIf="
                (password?.touched || password?.dirty) &&
                !password?.errors?.required
              "
              class="mt-3"
            >
              <span
                [ngClass]="
                  validationService.hasMinLength(data.password)
                    ? 'text-success'
                    : 'text-muted'
                "
                class="d-block mb-3"
              >
                <fa-icon icon="check"></fa-icon>&nbsp;Enter minimum 8
                characters</span
              >
              <span
                [ngClass]="
                  validationService.hasUpperCase(data.password) &&
                  validationService.hasLowerCase(data.password)
                    ? 'text-success'
                    : 'text-muted'
                "
                class="d-block text-success mb-3"
              >
                <fa-icon icon="check"></fa-icon>&nbsp;At least 1 uppercase and 1
                lowercase letter</span
              >
              <span
                [ngClass]="
                  validationService.hasNumber(data.password)
                    ? 'text-success'
                    : 'text-muted'
                "
                class="d-block text-success mb-3"
              >
                <fa-icon icon="check"></fa-icon>&nbsp;At least 1 number</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb0">
            <label for="phone_num"> Phone </label>
          </div>
        </div>
        <div class="col-md-3 pr0">
          <div class="form-group phoneNumber mb0">
            <div class="common-input-box">
              <div class="ext-wrp">
                <international-phone-number
                  id="international_number"
                  [(ngModel)]="data.country_code"
                  [maxlength]="20"
                  [ngModelOptions]="{ standalone: true }"
                  [required]="true"
                  #countryCode="ngModel"
                >
                </international-phone-number>
                <div class="input-val">
                  {{ data.country_code ? data.country_code : '+91' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 pl0">
          <div class="form-group mb0">
            <div class="common-input-box sa-phone-box">
              <input
                type="tel"
                id="phone_num"
                name="phone"
                #phone="ngModel"
                [(ngModel)]="data.phone"
                pattern="[0-9]+"
                minlength="10"
                maxlength="10"
                class="common-input"
                (keypress)="onlyNumber($event)"
                required
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <div class="invalid-feedback fs-5" *ngIf="checkIfMobileValid()">
              Mobile number should be 10 digits. Please enter valid phone number
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group m12">
            <button
              type="submit"
              class="btn btn-primary comm-login-module-btn"
              name="submit"
              (click)="singupClick()"
              [disabled]="
                checkSignupValidity() || signupform.invalid || signUpLoading
              "
            >
              <ng-container *ngIf="signUpLoading"
                ><span
                  class="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                ></span
                >&nbsp;&nbsp;Please wait...</ng-container
              ><ng-container *ngIf="!signUpLoading">Signup</ng-container>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="!showVerifyPage">
      <p>
        Already have an account?
        <a [routerLink]="['/login']">Login</a>
      </p>
    </div>
    <div *ngIf="showVerifyPage">
      <h2>Please verify your email</h2>
      <p class="pb-4">
        Once you verify your email address, you can get started with PM Tool.
      </p>
      <p>
        Back to
        <a [routerLink]="['/login']">Login</a>
      </p>
    </div>
  </div>
</section>
