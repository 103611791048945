import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { ValidationService } from '../../../shared/shared-module/services/validation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('password', { static: true }) public password;
  @ViewChild('cpassword', { static: true }) public confirmPassword;
  public data = {
    reset_password_token: '',
    password: '',
    password_confirmation: ''
  };
  public hideShow = { passShow: false, confirmShow: false };
  public CommonErrorMsg = '';

  constructor(
    public commonService: CommonService,
    public dataService: DataService,
    public router: Router,
    public apiRequest: ApiRequests,
    public activatedRoute: ActivatedRoute,
    public validationService: ValidationService
  ) {}

  public ngOnInit() {
    this.data.reset_password_token = window.location.href
      .split('?')[1]
      .split('=')[1];
    this.commonService.newHeadertrue = true;
    if (this.dataService.getUserData()) {
      this.commonService.newHeadertrue = false;
      this.router.navigate(['/projects']);
      return;
    }
    if (this.data.reset_password_token) {
      localStorage.clear();
      return;
    }
  }

  checkPass(): boolean {
    if (
      this.password &&
      this.confirmPassword &&
      this.password.touched &&
      this.confirmPassword.touched &&
      this.password.value.length >= 8 &&
      this.confirmPassword.value.length >= 8
    ) {
      if (
        this.password.value === this.confirmPassword.value &&
        this.password.value !== '' &&
        this.confirmPassword.value !== ''
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  public checkpassValidity(): boolean {
    return (
      !this.data.reset_password_token ||
      !this.data.password ||
      !this.data.password_confirmation ||
      this.ispasswordError(true) ||
      this.ispasswordError()
    );
  }

  public resetClick() {
    this.apiRequest.login_signup(this.data, 'reset').subscribe(
      () => {
        this.commonService.resetSucess = true;
        this.commonService.newHeadertrue = true;
        this.router.navigate(['login']);
      },
      (error) => {
        this.CommonErrorMsg = error.error;
      }
    );
  }

  public ispasswordError(isConfirmpass?: boolean): boolean {
    if (isConfirmpass) {
      return (
        this.confirmPassword &&
        (this.confirmPassword.touched || this.confirmPassword.dirty) &&
        !this.checkConfirmPassword()
      );
    }
    return (
      this.password &&
      (this.password.touched || this.password.dirty) &&
      !this.checkPassword()
    );
  }

  checkPassword() {
    return this.validationService.passwordValidation(this.data.password);
  }

  checkConfirmPassword() {
    return this.validationService.passwordValidation(
      this.data.password_confirmation
    );
  }
}
