import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { debounceTime } from 'rxjs';
import { ApiRequests } from '../../shared-module/services/apiRequests.service';
import { CommonService } from '../../shared-module/services/common.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnDestroy, OnChanges {
  memberList = [];
  isShowPaginationLoader = false;
  isSearchLoader = false;
  page: number;
  itemPerPage: number;
  clientList = [];
  filteredClientList = [];
  managerSubscription;
  currentTimeOut;
  userInitialColor = ['#7C4DFF', '#FFAB00', '#00C853', '#FF5546'];

  @Input() participantList = [];
  @Input() searchText = ' ';
  @Input() enterPressed = false;
  @Output() selectedMember = new EventEmitter<string>();
  @ViewChild('dropdownRef', { static: false }) dropdownRef: ElementRef;
  isShowDropdown = true;
  mergedMemberList = [];

  constructor(
    public apiRequestService: ApiRequests,
    public commonService: CommonService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.searchText &&
      this.searchText?.trim() &&
      changes.searchText.previousValue !== changes.searchText.currentValue
    ) {
      this.isShowDropdown = true;
      this.getManagers();
    }

    if (
      changes.enterPressed?.previousValue !==
        changes.enterPressed?.currentValue &&
      this.enterPressed &&
      this.mergedMemberList.length > 0
    ) {
      this.selectMember(this.mergedMemberList[0]);
    }
  }

  getManagers(response?) {
    if (response !== 'scroll') {
      this.page = 1;
      this.isShowPaginationLoader = false;
      this.isSearchLoader = true;
    } else {
      this.isShowPaginationLoader = true;
    }
    this.itemPerPage = 16;
    if (this.managerSubscription) {
      this.managerSubscription.unsubscribe();
    }
    this.managerSubscription = this.apiRequestService
      .getUsers(
        this.page,
        this.itemPerPage,
        this.searchText,
        'unarchived',
        true
      )
      .pipe(debounceTime(300))
      .subscribe(
        (data) => {
          this.isSearchLoader = false;
          if (response === 'scroll') {
            this.memberList = this.memberList.concat(data.users);
          } else {
            this.memberList = data.users;
          }
          this.mergedMemberList = [
            ...this.filteredClientList,
            ...this.memberList
          ].filter(
            (item, index, items) =>
              items.findIndex((x) => item.email === x.email) === index
          );

          if (
            this.mergedMemberList.length === 1 &&
            this.searchText.split(' ').filter((x) => x.trim() !== '').length > 2
          ) {
            this.selectMember(this.mergedMemberList[0]);
          }
          this.page = data.next_page;
          this.isShowPaginationLoader = false;
        },
        () => {
          this.isSearchLoader = false;
          this.isShowPaginationLoader = false;
        }
      );
  }

  onScrollMember() {
    if (this.page !== null && !this.isShowPaginationLoader) {
      this.getManagers('scroll');
      this.isShowPaginationLoader = true;
    }
  }

  selectMember(member) {
    this.selectedMember.emit(member);
  }

  trackByMsgIdFunc(index, Obj) {
    return Obj.email;
  }

  @HostListener('document:click', ['$event'])
  public documentClickListner($event) {
    if (
      this.dropdownRef &&
      !this.dropdownRef.nativeElement.contains($event.target)
    ) {
      {
        this.isShowDropdown = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.managerSubscription) {
      this.managerSubscription.unsubscribe();
    }

    if (this.currentTimeOut) {
      clearTimeout(this.currentTimeOut);
    }
  }
}
